import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Badge2 from '../Badges/Badge2'


class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = { col: 0, id: "", show: "false" };

        this.onChoose = this.onChoose.bind(this);
        this.showBadge = this.showBadge.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this)
        this.ondragstart = this.ondragstart.bind(this)

    }
    onWheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)))
        event.currentTarget.scrollLeft -= (delta * 10)

    };
    onMouseDown(event) {

        event.preventDefault();
        let x = event.clientX;
        let el = event.currentTarget;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        function onMouseMove(event) {

            const delta = Math.max(-1, Math.min(1, event.clientX - x))
            el.scrollLeft -= (delta * 10)
        }

        function onMouseUp() {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
        }

    };

    ondragstart(e) {
        return false;
    };



    updateData = (value) => {
        this.setState({ col: this.state.col + value })
    }


    change(e) {

        $('.active')[0].className = $('.active')[0].className.replace(" active", "");

        e.target.className += " active";

        if (e.target.id === '1') {
            $('.softskills').show();
            $('.productskills').hide();

        }
        if (e.target.id === '2') {
          //  $('.softskills').hide();
          //  $('.productskills').show();

        }


    }
    onChoose(e) {
        $('#badge').fadeToggle();

        $('#hidemarket').hide();
        if (e.target.id === '1') {
            this.setState({ id: '1' });

        }
        if (e.target.id === '2') {
            this.setState({ id: '2' })
        }
        if (e.target.id === '3') {
            this.setState({ id: '3' })
        }
        if (e.target.id === '4') {
            this.setState({ id: '4' })
        }
        if (e.target.id === '5') {
            this.setState({ id: '5' })
        }
        if (e.target.id === '6') {
            this.setState({ id: '6' })
        }

        this.setState({ show: "true" });

    }

    showBadge() {
        if (this.state.show === "true") {
            return <Badge2 id={this.state.id} updateData={this.updateData} />

        }

    }



    render() {

        return (
            <div>
                <div id="hidemarket">

                    <ul className="navbar-nav col-10">
                        <li className="nav-item active" onClick={this.change} >
                            <a className="nav-link" href="#ss" id="1">Soft Skills</a>
                        </li>
                        <li className="nav-item" onClick={this.change} >
                            <a className="nav-link" href="#ps" id="2">Product Skills</a>
                        </li>
                    </ul>

                    <div className="softskills">
                        <p className="category col-11 m-auto">Sales</p>
                        <div className="sssales col-11" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                            <img src="/imgs/course1.svg" id='1' onClick={this.onChoose} ></img>
                            <img src="/imgs/course2.svg" id='2' onClick={this.onChoose} ></img>

                        </div>

                        <p className="category col-11 m-auto">Management</p>
                        <div className="ssmanagement col-11" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                            <img src="/imgs/course3.svg" id='1' onClick={this.onChoose} ></img>
                            <img src="/imgs/course4.svg" id='2' onClick={this.onChoose} ></img>
                        </div>
                    </div>


                    <div className="productskills">

                    </div>



                    <div className="menu_btm col-12">
                        <Link to="/courses"><img id="imgs" src="/imgs/Option1a.svg"></img></Link>
                        <Link to="/rating"><img id="imgs1" src="/imgs/Option3.svg"></img></Link>
                        <Link to="/profile"><img id="imgs2" src="/imgs/Option2.svg"></img></Link>

                    </div>

                </div>
                <div id="badge" style={{ display: "none", background: "#FFFFFF" }}>
                    {this.showBadge()}
                </div>

            </div>
        );
    }
}
export default Courses
