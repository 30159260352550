import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link } from 'react-router-dom'



class Rating extends Component {


    constructor(props) {
        super(props);




        this.onWheel = this.onWheel.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this)
        this.ondragstart = this.ondragstart.bind(this)

    }
    onWheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)))
        event.currentTarget.scrollLeft -= (delta * 10)
      
    };
    onMouseDown(event){
      
        event.preventDefault();
        let x = event.clientX;
        let el= event.currentTarget;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
  
        function onMouseMove(event) {

            const delta = Math.max(-1, Math.min(1, event.clientX - x))
            el.scrollLeft -= (delta * 10)
        }
  
        function onMouseUp() {
          document.removeEventListener('mouseup', onMouseUp);
          document.removeEventListener('mousemove', onMouseMove);
        }
  
      };
  
      ondragstart (e) {
        return false;
      };
       
    


    render() {

        return (
            <div>
                <div id="hidesale">

                        <h1>Rating</h1>
                        <div className="ratings" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>

                            <img src="/imgs/Frame31.png"></img>

                        </div> 

                    <div className="menu_btm col-12">
                        <Link to="/courses"><img id="imgs" src="/imgs/Option1.svg"></img></Link>
                        <Link to="/rating"><img id="imgs1" src="/imgs/Option3a.svg"></img></Link>
                        <Link to="/profile"><img id="imgs2" src="/imgs/Option2.svg"></img></Link>

                    </div>

                </div>

            </div>
        );
    }
}
export default Rating

