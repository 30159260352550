import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './components/App';
import Registration from './components/Registration';
import Badge2 from './components/Badges/Badge2';
import Profile from './components/Profile';
import Rating from './components/Rating';
import Courses from './components/Courses';




ReactDOM.render((

    <Router>
        <Route exact path="/" component={App} />
        <Route path="/login" component={Registration} />
        <Route path='/profile' component={Profile}/>
        <Route path='/rating' component={Rating}/>
        <Route path='/courses' component={Courses}/>
        <Route path="/badge"  component={Badge2}/>
       
    </Router>
),
    document.getElementById('rootIn'))