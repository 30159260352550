import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'

import './style.css'

import $ from 'jquery'


class Badge2 extends Component {
    constructor(props) {
        super(props);
        this.state = { col: 0 };

        this.close = this.close.bind(this);
        this.change = this.change.bind(this);


    }


    close() {
        if ($('.coursedescription')[0].style.display == 'none' && $('.test')[0].style.display !== 'none') {
            $('.coursedescription').show();
            $('.test').hide()
        }
        else {
            $('#badge').fadeToggle();
            $('#hidemarket').show();
            $('.result').hide();
            $('.coursedescription').show();
            $('.topback').show();
            $('.coursedescription').scrollTop(0,0);
        }
    }
    change() {

        if ($('.test')[0].style.display !== 'none') {
            $('.result').show();
            $('.test').hide();
           
        }
        else {
            $('.coursedescription').fadeToggle();
            $('.test').show();
        }

        if ($('.test')[0].style.display == 'none' && $('.coursedescription')[0].style.display == 'none' ) {
            $('.topback').hide();
        }


    }







    render() {

        return (
            <div style={{ background: '#FFFFFF', minHeight: "100vh" }}>

                <div className="topback">
                    <img src="/imgs/left.svg" onClick={this.close}></img>
                    <p>8 stages of the sales process</p>
                </div>

                <div className="coursedescription col-10 m-auto" >
                    <h1>Defining the 8 stages of the sales process</h1>
                    <p>Defining a clear and well-thought-out sales process goes well beyond simply knowing how to close a deal.
                        <br></br><br></br>  Your sales process is composed of a series of steps, which your sales team will encounter when escorting a prospect through the sales funnel. This procedure begins from the very first moment that your company makes contact with a prospect and will often continue long after the sale is complete.
                        <br></br><br></br>An effective sales process encompasses all worthwhile customer interactions, from prospecting to selling and nurturing.
                        <br></br> <br></br>But, why does detailing your sales process matter at all?
                        <br></br><br></br>Well, a clear, transparent process means that you can achieve the following:
                        <ul>
                            <li> An improved understanding of each stage of the sales pipeline</li>
                            <li>An opportunity to develop more sophisticated sales and marketing strategies</li>
                            <li>A smoother buying process for the customer</li>
                            <li> A better training process when training new hires and current staff</li>
                        </ul>

                        Additionally, the most significant benefit of having this clear-cut sales process is the fact that your business has the opportunity to nurture long-lasting relationships, which means higher customer lifetime value, reduced acquisition costs, and increased profit potential – all of which are vital lifelines for small businesses.</p>
                    <img className="img-fluid" src="/imgs/imageCourse.png"></img>
                    <h1 style={{marginTop:'40px'}}>How to Create a Sales Process That Breeds Success</h1>
                    <p>Before you can so much as talk about developing an effective sales process, you must first work out what your sales team's process looks like right now.
                        <br></br><br></br>Once you're familiar with what you're working with, you can make decisions on what steps must be taken to improve or bridge gaps in the current system.
                        <br></br><br></br>You can do the following things to help you formulate an official sales process that aligns with your company values and complements the way your sales team works:
                        <br></br><br></br><b>Review historic sales data</b> – Take a stroll down memory lane and look at each team members' sales contributions to identify strengths, weaknesses, and gaps in their knowledge or technique. Are there patterns such as prospects falling out of the sales funnel at a specific point? Is your team following up on opportunities promptly? How long is the sales process from beginning to end on average?
                        <br></br><br></br> <b>Spend time with each member of the team to learn about their processes</b> – It's an all too common phenomenon for organizations to look at results over process. However, once the process has been finely tuned, you'll find that your results will significantly improve. This is why it's such a good idea to sit down with your team and find out how they're currently going about trying to turn prospects into customers.
                        <br></br><br></br> A great way to get a dialogue going is to select a recent acquisition and ask the following questions:
                        <ul>
                            <li>Which platform was used to initiate the contact? Social media, email, phone call, etc.</li>
                            <li> How long did it take to get a response from the lead?</li>
                            <li> How often were you reaching out?</li>
                            <li> What points did you cover in your initial conversation?</li>
                            <li> Were there any sales objections put forward by the prospect? If so, what were they?</li>
                            <li> Was contact made with the customer after the deal was completed?</li>
                        </ul>

                        Based on the customer data you have stored away and the in-depth knowledge you've gleaned from the sales team, you can now breakdown the buyer journey into eight clearly-defined stages and identify what action must be taken before a prospect can move to the next stage.
                    </p>
                    <button className="m-auto" onClick={this.change}>Go to test</button>
                </div>

                <div className="test col-10 m-auto" >
                    <h1>A clear, transparent sale process means that you can achieve the following:</h1>
                    <img className="img-fluid" src="/imgs/Group94.svg"></img>
                    <h1 style={{marginTop:'40px'}}>What can you do to help to formulate an official sales process that aligns with your company values and complements the way your sales team works:</h1>
                    <img className="img-fluid" src="/imgs/Group94_2.svg" ></img>
                    <button className="m-auto" onClick={this.change}>Confirm</button>
                </div>

                <div className="result col-10 m-auto">
                    <h1>Congratulation!</h1>
                    <p>You have got a new certificate</p>
                    <img className="img-fluid" src="/imgs/stars.svg" ></img>
                    <img className='resultlast img-fluid' src="/imgs/cert.svg" style={{marginBottom:'40px'}} ></img>
                    <button className="m-auto" onClick={this.close}>Back to courses</button>
                </div>
            </div>
        );
    }
}
export default Badge2

