import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'

import $ from 'jquery'



class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password: '', write: 0 };


        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeAgree = this.onChangeAgree.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }


    onChangeEmail(event) {
        $('#incorr-em').hide();
        this.setState({ email: event.target.value });
        $('form').css({ "margin-top": '0' });
        $('.btn-l').css({ 'bottom': "-30%" })
    }

    onChangePassword(event) {
        $('#incorr-pas').hide();
        this.setState({ password: event.target.value });
        $('form').css({ "margin-top": '0' });
        $('.btn-l').css({ 'bottom': "-30%" })
    }

    onChangeAgree() {
        $('#incorr-agree').hide();
        $('form').css({ "margin-top": '0' });
        $('.btn-l').css({ 'bottom': "-30%" })
    }

    onSubmit(event) {
        var status = 0;
        event.preventDefault();
        if (this.state.password !== "EasyGrow2023")  {
            $('#incorr-pas').show()
            $('form').css({ "margin-top": '0' });
            $('.btn-l').css({ 'bottom': "-20%" })
            $('.img-r').css({'width':"50%"})
        }
        else {
            status += 1
        }
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(this.state.email)) {
            $('#incorr-em').show();
         
        }
        else {
            status += 1
        }
        if ($('#agree')[0].checked) {
            status += 1
        }
        else {
    
            $('#incorr-agree').show()     
        }

        if (status == 3) {
            fetch('https://api.backendless.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/data/emails')
                .then(response => response.json())
                .then(data => {
                    for (let i = 0; i < data.length; i++) {

                        if (data[i].email === this.state.email) {
                            this.setState({ write: this.state.write + 1 })

                        }
                    }

                    if (this.state.write === 0) {
                        fetch('https://api.backendless.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/data/emails', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'cash-control': 'no-cash' },
                            body: JSON.stringify({ 'email': this.state.email })
                        }).then(response => response.json())
                            .then(data => window.location.href = '/profile');
                    }
                    else {
                        window.location.href = '/profile'
                    }

                });







        }

    }

    render() {
        return (
            <div>
                <div className="img">
                    <img className="img-r" src="/imgs/group92.png"></img>
                </div>

                <form onSubmit={this.onSubmit} className="col-10">
                    <div className="form-group">
                        <label htmlFor="InputEmail">Email address</label>
                        <input type="email" className="form-control" id="InputEmail" value={this.state.email} onChange={this.onChangeEmail}></input>
                    </div>
                    <p id="incorr-em" className="incorr" style={{ display: "none" }}>incorrect email</p>
                    <div className="form-group">
                        <label htmlFor="InputPassword">Password</label>
                        <input type="password" className="form-control" id="InputPassword" value={this.state.password} onChange={this.onChangePassword} />
                    </div>
                    <p id="incorr-pas" className="incorr" style={{ display: "none" }}>incorrect password</p>
                    <p className="f-p">Forgot password?</p>
                    <div className="agree">
                        <input className="custom-checkbox" type="checkbox" id="agree"  onChange={this.onChangeAgree}/>
                        <label htmlFor="agree"><p>I accept <a href="/privacy_policy.html" target="_blank"> Privacy Policy</a></p></label>
                       
                    </div>
                    <p id="incorr-agree" className="incorr" style={{ display: "none", fontSize: "14px", whiteSpace: "nowrap" }}>You need to accept privacy policy to login</p>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button type="submit" className="btn-l col-11">Login</button>
                    </div>

                </form>
            </div>
        );
    }
}
export default Registration


